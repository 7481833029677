import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import Launch from "./components/launch/Launch";
import { Art } from "./components/art-installations/Art-Installations";
import About from "./components/about/AboutPage";
import { Street } from "./components/photography/Street";
import { Commercial } from "./components/photography/Commercial";
import Error404 from "./components/error/error404";
import { Portraiture } from "./components/photography/Portraiture";
import Videography from "./components/videography/Videography";
import { NavBar } from "./components/nav/Navbar";

class App extends Component {

  render() {
    return (
      <div>
        <Switch>
          <Route path="/nav" component={NavBar} />
          <Route path="/" exact component={Launch} />
          <Route path="/art/" component={Art} />
          <Route path="/about/" component={About} />
          <Route path="/photography/street" component={Street} />
          <Route path="/photography/commercial" component={Commercial} />
          <Route path="/photography/portraiture" component={Portraiture} />
          <Route path='/videography' component={Videography} />
          <Route component={Error404} />
        </Switch>
      </div>
    )
  }
}

export default App;

