import React from "react";

export class Footer extends React.Component<any, any> {
    footerRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);

        this.footerRef = React.createRef();
    }

    render() {
        return (
            <div className='footer' key="footer" ref={this.footerRef} style={{...this.props.style}}>
                <a href="https://www.facebook.com" className="fab fa-facebook"></a>
                <a href="https://instagram.com/creative.x.tendencies?igshid=2f0puxocq85x" className="fab fa-instagram"></a>
                <a href="https://www.twitter.com" className="fab fa-twitter"></a>
                <a href="mailto:creativetendencies@creativextendencies.com" className="fas fa-envelope"></a><br />
                <span>Developed By Dominique Felix</span>
            </div>
        )
    }
}