import React from 'react';
import { NavBar } from '../nav/Navbar';
import { Footer } from '../footer/Footer';
import windowSize from 'react-window-size';

export class Videography extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = { 
      loaded: false
    }

    this.footerSize = this.footerSize.bind(this);
  }

  footerSize() {
    if (this.props.windowWidth < 400) {
      return (<Footer style={{ position: 'relative', bottom: 0 }} />)
    } else {
      return (<Footer style={{ position: 'sticky', bottom: 0 }} />)
    }
  }

  render() {
    return (
      <div className='main'>
        <NavBar />
        <h1 className="photography-h1">VIDEOGRAPHY</h1>
        <iframe width="800" height="600" src="https://www.youtube.com/embed/YVlotXWfLdw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe><br />
        <iframe width="800" height="600" src="https://www.youtube.com/embed/GyOOfjWBcyc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe><br />
        <iframe width="800" height="600" src="https://www.youtube.com/embed/ujSRkBnxtTU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe><br />
        {this.footerSize()}
      </div>
    );
  }
}

export default windowSize(Videography);
