import React, { Component } from "react";
import { Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Collapse } from "reactstrap";

export class NavBar extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            dropdownOpen: false
        },
            this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }

    render() {

        return (
            <div>
                <Navbar expand="lg" fixed="top" >
                    <NavbarBrand href="/"  className="mr-auto">CREATIVE x TENDENCIES</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} className="custom-toggler mr-2"/>
                    <Collapse isOpen={this.state.dropdownOpen} navbar>
                        <Nav>
                            <NavItem><NavLink href='/photography/street' >STREET</NavLink></NavItem>
                            <NavItem><NavLink href='/photography/portraiture'>PORTRAITURE</NavLink></NavItem>
                            <NavItem><NavLink href='/photography/commercial'>COMMERCIAL</NavLink></NavItem>
                            {/* <NavItem><NavLink href='/art'>ART INSTALLATIONS</NavLink></NavItem> */}
                            <NavItem><NavLink href='/videography'>VIDEOGRAPHY</NavLink></NavItem>
                            <NavItem><NavLink href='/about'>ABOUT</NavLink></NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        )
    }
}