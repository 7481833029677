import React from 'react';
import { NavBar } from '../nav/Navbar';
import { Footer } from '../footer/Footer';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';

export class Error404 extends React.Component<any, any> {
  private el: any
  private typed: any

  constructor(props: any) {
    super(props);
    this.state = {
      options: {
        strings: [`<i>404, page not found.</i> \n <i>Click here to go <a href='/'>Home</a></i>`],
        typeSpeed: 40,
        onStringTyped: (arrayPos: number, self: { stop: () => void; }) => {
          if (arrayPos == 1) {
            self.stop();
            <a style={{  }}></a>
          }
        }
      }
    }
  }

  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.typed.destroy();
  }

  componentDidMount() {
    this.typed = new Typed(this.el, this.state.options);

  };

  render() {
    return (
      <div className="wrap">
        <div className="type-wrap">
          <span
            style={{ whiteSpace: 'pre' }}
            ref={(el) => { this.el = el; }}
          />
        </div>
      </div>
    )
  }
}

export default Error404;
