import React from 'react';
import { NavBar } from '../nav/Navbar';
import { Footer } from '../footer/Footer';
import { Button } from 'reactstrap';
import windowSize from 'react-window-size';
let smallPhoto = require('../../assets/IMG_0264-4.jpg');
let mediumPhoto = require('../../assets/IMG_0264-3.jpg');
let xmediumPhoto = require('../../assets/IMG_0264-2.jpg');
let normalPhoto = require('../../assets/IMG_0264-1.jpg')

export class AboutPage extends React.Component<any, any> {

  constructor(props: any) {
    super(props);

    this.getImages = this.getImages.bind(this);
    this.getFooter = this.getFooter.bind(this);
  }

  getImages() {
    if (this.props.windowWidth <= 400) {
      return (
        <>
          <img src={smallPhoto} className="about-me-img" />
          <br /><br /><br /><br />
        </>
      )
    } else if (this.props.windowWidth > 400 && this.props.windowWidth <= 607) {
      return (
        <>
          <img src={mediumPhoto} className="about-me-img" />
          <br /><br /><br /><br />
        </>
      )
    } else if (this.props.windowWidth > 607 && this.props.windowWidth <= 815) {
      return (
        <>
          <img src={xmediumPhoto} className="about-me-img" />
          <br /><br /><br /><br />
        </>
      )
    } else {
      return (
        <>
          <img src={normalPhoto} className="about-me-img" />
          <br /><br /><br /><br />
        </>
      )
    }
  }

  getFooter() {
    if (this.props.windowWidth <= 400) {
      return (
        <Footer style={{ position: 'absolute' }}/>
      )
    } else {
      return (
        <Footer style={{ position: 'sticky', bottom: 0 }}/>
      )
    }
  }

  render() {
    return (
      <div className="main about">
        <NavBar />
        <h1 className="photography-h1">ABOUT</h1> 
        <div className="about-me-para"><p>CREATIVE X TENDENCIES</p>
        <p>A New York and Miami based photographer and film director.</p>
        <p>The nature of creative tendencies is based on the ability to manifest vulnerable ideas and thought-provoking questions into art. 
        Internal struggles allowed insecurities to manifest into a paralyzing energy that needed transferring. </p>
        <p>A shotgun purchase of a cheap DSLR allowed that energy to transfer for the first time. 
        Creative Tendencies bloomed into the umbrella brand for all collaborations, freelance work, and personal artistic endeavors.</p>
        <p>Creative Tendencies has had an array of projects working with Gabriel Miami hotel, CGI Merchant Group, and musicians. 
        Learning, expression, and living free are the mission.</p>
        <strong>Feel the soul in the work.</strong></div><br /><br />
        <Button outline size="lg" className="about-btn" ><a style={{ textDecoration: "none", color: '#FFFFFF', borderColor: '#FFFFFF' }} href="mailto:creativetendencies@creativextendencies.com">CONTACT</a></Button><br /><br /><br /><br /><br /><br />
        {this.getImages()}
        {this.getFooter()}
      </div>
    );
  }
}

export default windowSize(AboutPage);

