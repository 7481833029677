import React, { Component, createRef } from "react";
import logoMed from '../../assets/creative tendencies-reg(white).png';
import logoSmall from '../../assets/creative tendencies-small(white).png';
import logoLarge from '../../assets/creative tendencies-large(white).png';
import { Footer } from "../footer/Footer";
import { Button } from "reactstrap";
import BackgroundSlider from 'react-background-slider';
import windowSize from 'react-window-size';
let launchvideo = require('../../assets/IMG_0369.mov');
let aws = require('aws-sdk');

class Launch extends Component<any, any> {
    private videoRef = createRef<HTMLVideoElement>();
    private imgRef = createRef<HTMLImageElement>();
    private btnRef = createRef<HTMLButtonElement>();
    private btnRef1 = createRef<HTMLButtonElement>();
    private footerRef = createRef<Footer>();
    private h4ref = createRef<HTMLHeadingElement>();

    constructor(props: any) {
        super(props);
        this.state = {
            redirect: false,
            pictures: [],
            accessKeyId: 'AKIAXTXLFCSNFN7ECTYP',
            secretAccessKey: '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',
            region: 'us-east-1',
            params: {
                Bucket: 'creative-tendencies-test-s3',
                MaxKeys: 100
            },
            loading: true
        }

        this.setRedirect = this.setRedirect.bind(this);
        this.renderRedirect = this.renderRedirect.bind(this);
        this.hideVideo = this.hideVideo.bind(this);
        this.whichLogo = this.whichLogo.bind(this);
        this.renderAboutRedirect = this.renderAboutRedirect.bind(this);
    }

    setRedirect() {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {
        this.props.history.push("/photography/street");
    }

    renderAboutRedirect = () => {
        this.props.history.push("/about")
    }


    hideVideo() {
        const video = this.videoRef.current;
        const button = this.btnRef.current;
        const button1 = this.btnRef1.current;
        const logo = this.imgRef.current;
        const footer = this.footerRef.current;
        const heading = this.h4ref.current;

        if (video && logo && button && footer && footer.footerRef && footer.footerRef.current && button1 && heading) {
            video.style.visibility = "hidden";
            video.style.opacity = "0";
            video.style.transition = "visibility 0s 2s, opacity 2s linear";
            logo.style.visibility = "visible";
            button.style.visibility = "visible";
            button1.style.visibility = "visible";
            footer.footerRef.current.style.visibility = "visible";
            heading.style.visibility = "visible"
        }
    }

    async componentDidMount() {
        let config = new aws.Config({

            accessKeyId: this.state.accessKeyId,
            secretAccessKey: this.state.secretAccessKey,
            region: this.state.region
        });
        let s3 = new aws.S3(config);

        let tempKeys: any[] = [];
        let tempUrls: any[] = [];

        const data = await s3.listObjectsV2(this.state.params).promise();

        data.Contents.forEach((element: any) => {
            let substring = element.Key.substring(0, 6);
            if (element.Size > 0 && substring === "launch") {
                tempKeys.push(element.Key);
            }
        }),

        tempKeys.forEach((element: any) => {
            let params = {
                Bucket: 'creative-tendencies-test-s3',
                Key: element
            }
            tempUrls.push(s3.getSignedUrl('getObject', params))
        })

        this.setState({
            pictures: tempUrls,
            loading: false
        })
    }

    whichLogo() {
        if (this.props.windowWidth <= 700) {
            return (
                <>
                    <img className="launch-logo" src={logoSmall} ref={this.imgRef} style={{ visibility: "hidden" }} />
                    <Button style={{ visibility: "hidden", color: '#FFFFFF', borderColor: '#FFFFFF' }} className="launch-btn" innerRef={this.btnRef} outline  size="sm" onClick={() => this.renderAboutRedirect()}>MEET C x T</Button>
                    <Button style={{ visibility: "hidden", color: '#FFFFFF', borderColor: '#FFFFFF' }} className="about-launch-btn" innerRef={this.btnRef1} outline size="sm" onClick={() => this.renderRedirect()}>PORTFOLIO</Button>
                </>
            )
        }
         else if (this.props.windowWidth > 700 && this.props.windowWidth <= 950) {
            return (
                <>
                    <img className="launch-logo" src={logoMed} ref={this.imgRef} style={{ visibility: "hidden" }} />
                    <Button style={{ visibility: "hidden", color: '#FFFFFF', borderColor: '#FFFFFF',  }} className="launch-btn" innerRef={this.btnRef} outline size="sm" onClick={() => this.renderAboutRedirect()}>MEET C x T</Button>
                    <Button style={{ visibility: "hidden", color: '#FFFFFF', borderColor: '#FFFFFF',  }} className="about-launch-btn" innerRef={this.btnRef1} outline size="sm" onClick={() => this.renderRedirect()}>PORTFOLIO</Button>
                </>
            )
        }  else if (this.props.windowWidth > 950) {
            return (
                <>
                    <img className="launch-logo" src={logoLarge} ref={this.imgRef} style={{ visibility: "hidden" }} />
                    <Button style={{ visibility: "hidden", color: '#FFFFFF', borderColor: '#FFFFFF',  }} className="launch-btn" innerRef={this.btnRef} outline size="md" onClick={() => this.renderAboutRedirect()}>MEET C x T</Button>
                    <Button style={{ visibility: "hidden", color: '#FFFFFF', borderColor: '#FFFFFF',  }} className="about-launch-btn" innerRef={this.btnRef1} outline size="md" onClick={() => this.renderRedirect()}>PORTFOLIO</Button>
                </>
            )
        }
    }

    render() {
        if (this.state.loading) {
            return (
                null
            )
        } else {
            return (
                <div className="launchDiv">
                    <video className='react-player' ref={this.videoRef} autoPlay muted onEnded={this.hideVideo} playsInline><source src={launchvideo} /></video>
                    <BackgroundSlider style={{ visibility: "hidden" }} images={this.state.pictures} duration={8} transition={2} />
                    <h4 className="services" ref={this.h4ref} style={{ visibility: "hidden" }}>PHOTOGRAPHY | VIDEOGRAPHY</h4>
                    {this.whichLogo()}
                    <Footer ref={this.footerRef} style={{ visibility: "hidden", position: 'absolute'}} />
                </div>
            )
        }
    }
}

export default windowSize(Launch);