import React from 'react';
import { NavBar } from '../nav/Navbar';
import { Footer } from '../footer/Footer';
let aws = require('aws-sdk');
import ImageGallery from 'react-image-gallery';

export class Street extends React.Component<any, any> {
    s3urls: any = []
    finalUrls: any = []
    images: any = []

    constructor(props: any) {
        super(props);
        this.state = {
            accessKeyId: 'AKIAXTXLFCSNFN7ECTYP',
            secretAccessKey: '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',
            region: 'us-east-1',
            pictures: [],
            params: {
                Bucket: 'creative-tendencies-test-s3',
                MaxKeys: 100
            },
        }
        this.renderImages = this.renderImages.bind(this);
    }

    async componentWillMount() {
        let config = new aws.Config({

            accessKeyId: this.state.accessKeyId,
            secretAccessKey: this.state.secretAccessKey,
            region: this.state.region
        });
        let s3 = new aws.S3(config);

        let tempKeys: any[] = [];

        const data = await s3.listObjectsV2(this.state.params).promise();

        data.Contents.forEach((element: any) => {
            let substring = element.Key.substring(0, 6);
            if (element.Size > 0 && substring === "street") {
                tempKeys.push(element.Key);
            }
        }),

        this.setState({
            pictures: tempKeys
        })
    }

    renderImages() {
          let temp = [];
          for (let i = 0; i < this.state.pictures.length; i++)  {
              temp[i] = {
                  original: `https://creative-tendencies-test-s3.s3.amazonaws.com/${this.state.pictures[i]}`
              }
          }

          return temp;
    }

    render() {

        return (
            <div className="main">
                <NavBar props={this.props} />
                <h1 className="photography-h1">STREET</h1>
                <ImageGallery items={this.renderImages()} showPlayButton={false} showThumbnails={false} showFullscreenButton={false} />
                <Footer />
            </div>
        )
    }
}
