import React from 'react';
import { NavBar } from '../nav/Navbar';
import { Footer } from '../footer/Footer';

export class Art extends React.Component {
  render() {
    return (
      <div className='main'>
      <NavBar props={this.props} />
        <h1 className="photography-h1">ART INSTALLATIONS</h1><br/>
        <h2>COMING SOON - JUNE 2019</h2>
        <Footer />
      </div>
      );
  }
}